import React, { useState } from "react"
import containerClasses from "../../styles/container.module.css"
import elizabethCircular from "../../assets/images/elizabeth-circular-5.png"
import aestheticsJournalBlack from "../../assets/images/aesthetic-journal-black.svg"
import cmac from "../../assets/images/cmac-2022.png"
import tabsStyles from "../../styles/tabs.module.css"

type TabProps = {
  tabs: Array<{ id: number; title: string; content: string }>
}

export const Tab = ({ tabs }: TabProps) => {
  const [activeTab, setActiveTab] = useState(
    tabs.length > 0 ? tabs[0].id : null
  )

  return (
    <div className="bg-white2">
      <div
        className={`${containerClasses.container} relative py-12 md:py-16 lg:py-20`}
      >
        <div className="col-span-4 my-0 md:col-span-6 md:col-start-2 lg:col-span-6 lg:col-start-4">
          <h2 className="mb-0 text-center fluid-heading-05">
            Patient Safety Guide
          </h2>
        </div>
        {/* Tab Buttons */}
        <div className="col-span-4 lg:mb-6 md:col-span-4 md:col-start-3 lg:col-span-4 lg:col-start-5">
          <div
            className={`sticky flex justify-center border-2 border-solid rounded-full ${tabsStyles.safetyTabsButtons} border-blackNew`}
          >
            {tabs.map((tab, index) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-3 uppercase sticky py-2 ${
                  tabsStyles.safetyTabsHeading
                } tracking-wide font-semibold transition ${
                  index === 0 ? "rounded-tl-full rounded-bl-full " : ""
                } ${
                  index === tabs.length - 1
                    ? "rounded-tr-full rounded-br-full "
                    : ""
                } ${
                  activeTab === tab.id
                    ? "bg-blackNew text-white active" // Ensure these styles are applied when active
                    : "text-blackNew"
                } flex-1 md:basis-0`}
              >
                {tab.title}
              </button>
            ))}
          </div>
        </div>
        {/* Tab Content */}
        {tabs.map(tab => (
          <div
            key={tab.id}
            id={`tab.id`}
            className={`${tabsStyles.safetyTabs} ${
              activeTab == tab.id ? "flex" : "hidden"
            } col-span-4 md:col-span-8 flex flex-col border-t-4 lg:col-span-5 lg:col-start-7`}
          >
            <div dangerouslySetInnerHTML={{ __html: tab.content }} />
          </div>
        ))}
        {/* Tabs Image */}
        <div className="relative inline-block h-full col-span-4 md:col-span-8 lg:row-start-3 lg:col-span-5 lg:col-start-1">
          <img
            loading="lazy"
            src={elizabethCircular}
            alt="Tatler Logo"
            className="w-full lg:pb-0"
          />
          <div className="absolute right-0 flex items-center h-auto px-6 py-2 bg-white rounded-sm gap-x-4 backdrop-blur-lg bottom-1 md:bottom-8 lg:bottom-12">
            <img
              className="h-5"
              loading="lazy"
              alt="Complications in Medical Aesthetics Collaborative Logo"
              src={cmac}
            />

            {/* Vertical Line */}
            <div className="w-px h-8 bg-gray-300"></div>

            <img
              className="h-5"
              loading="lazy"
              alt="Aesthetics Journal Logo"
              src={aestheticsJournalBlack}
            />
          </div>
        </div>
        <p className="col-span-4 px-8 mt-0 text-xs font-bold leading-snug text-center text-gray-900 lg:col-span-5 lg:col-start-1 md:col-start-2 md:col-span-6">
          Dr Hawkes serves as a Clinical Advisor for the Complications in
          Medical Aesthetics Collaborative and Aesthetics Journal.
        </p>
      </div>
    </div>
  )
}
